<template>
    <admin-dashboard-layout :loading="loading">
        <v-container>
            <v-form>
                <v-card class="mx-15 px-15">
                    <v-card-title>
                        <v-row>
                            <v-col v-text="guide.name"/>
                            <v-col class="text-right">
                                <v-btn text :to="{name: 'admin.guides.index'}">return</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-select
                                v-model="guide.language"
                                :items="languages"
                                item-text="label"
                                item-value="code"
                                outlined
                                dense
                                label="Language">
                                <template slot="selection" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                            </v-select>

                            <v-text-field v-model="guide.name" label="Name" outlined dense/>
                            <v-textarea v-model="guide.description" label="Description" outlined dense/>
                            <v-textarea v-model="guide.notes" label="Notes" outlined dense/>
                            <v-row>
                                <v-col md="4" sm="12">
                                    <div>
                                        <v-carousel  height="auto" v-model="carousel">
                                            <v-carousel-item
                                                v-for="(image, i) in guide.gallery_images"
                                                :key="image+i"
                                            >
                                                <v-img contain :src="image" />
                                                <v-btn @click="handleRemoveGalleryImage(i)" v-text="'Remove'" color="red" class="absolute-top"/>
                                            </v-carousel-item>
                                        </v-carousel>

                                        <v-alert
                                            v-if="alertMessage"
                                            dense
                                            border="left"
                                            type="warning"
                                            class="mt-4"
                                            v-text="alertMessage"/>

                                    </div>
                                    <div class="mt-10">
                                        <vue-dropzone
                                            ref="galeryDropzone"
                                            id="dropzone"
                                            :options="galleryDropzoneOptions"
                                            @vdropzone-success="handlePushGalleryImage"/>
                                    </div>
                                    <div class="mt-10">
                                    </div>
                                </v-col>
                                <v-col md="8" sm="12">
                                    <v-textarea outlined v-model="guide.description" label="description"/>
                                    <AdminUploadFiles v-model="guide.files" name="Guide" :file="guide.files" directory="guides"/>
                                    <AdminUploadFiles v-model="guide.powerpoint" name="Powerpoint" :file="guide.powerpoint" directory="guides"/>
                                    <v-text-field label="Rank" v-model="guide.rank" outlined dense/>
                                    <v-select
                                        v-model="guide.tags"
                                        dense
                                        outlined
                                        label="Tags"
                                        item-text="name"
                                        item-value="id"
                                        :multiple="true"
                                        :items="tags"/>
                                    <v-select
                                        v-model="guide.industry_id"
                                        dense
                                        outlined
                                        label="Industry"
                                        item-text="name"
                                        item-value="id"
                                        :items="industries"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn @click="handleSave">  Save </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Guide from "../../../models/Guide";
    import vue2Dropzone from "vue2-dropzone";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import languages from "@/configs/languages";
    import Tag from "@/models/Tag";
    import Industry from "@/models/Industry";
    import AdminUploadFiles from "@/components/admin/AdminUploadFiles";
    import {mapMutations} from "vuex";

    export default {
        name: "Form",
        components: {AdminUploadFiles, AdminDashboardLayout, vueDropzone: vue2Dropzone},
        data: function () {
            return {
                alertMessage: null,
                loading: false,
                languages,
                carousel: 0,
                guide: {
                    language: 'en',
                    gallery_images: [],
                    files: '',
                    powerpoint: '',
                    notes: ''
                },
                galleryDropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 5,
                    params: {'directory': 'guides/images'},
                    headers: {
                        Authorization: null
                    }
                },
                languageDropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 10,
                    params: {'directory': 'guides'},
                    headers: {
                        Authorization: null
                    },
                    previewTemplate:  `
                    <div class="dz-preview dz-file-preview" style="min-width: 120px">
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                            <div class="dz-size" data-dz-size></div>
                        </div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    </div>
                `
                },
                newLanguage: '',
                newFile: '',
                tags: [],
                industries: []
            }
        },
        methods: {
            handleRemoveGalleryImage(index) {
                this.guide.gallery_images.splice(index,1);
                this.alertMessage = 'You must click on the save button';
            },
            handleRemoveLanguage(index) {
                this.guide.files.splice(index,1)
            },
            handleAddLanguage() {
                this.guide.files.push({
                    language: this.newLanguage,
                    url: this.newFile
                })
            },
            handlePushGalleryImage(file, res) {
                this.guide.gallery_images.push(res.url);
                this.carousel = this.guide.gallery_images.length;
                this.alertMessage = 'You must click on the save button';
            },
            handlePushLanguageFile(file, res) {
                this.newFile = res.url
            },
            async handleSave() {
                if (this.$_.isArray(this.guide.tags)) {
                    this.guide.tags = this.guide.tags.map((tag) => this.$_.isObject(tag) ? tag.id : tag);
                }

                const guide = new Guide(this.guide)
                this.guide = await guide.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
                if (this.guide.id && ! this.$route.params.id) {
                    await this.$router.push({name: 'admin.guides.show', params: {id: this.guide.id}})
                }
            },
            ...mapMutations(['showSnackBar']),
            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.guide.industry_id) {
                    this.guide.industry_id = this.industries[0].id
                }
            }
        },
        async mounted() {
            this.loading = true;
            const guideId = this.$route.params.id

            if (guideId) {
                this.guide = await Guide.include(['tags']).find(guideId);
            } else {
                let nextRank = await window.axios.get('/api/helpers/next-rank/guides');
                this.guide.rank = nextRank.data
                this.$forceUpdate()
            }

            await this.getIndustries();

            this.tags = await Tag.get();

            const token = await localStorage.getItem('auth_token_default')
            this.galleryDropzoneOptions.headers.Authorization = "Bearer " + token
            this.languageDropzoneOptions.headers.Authorization = "Bearer " + token
            this.loading = false;
        }
    }
</script>

<style scoped>
.absolute-top {
    position: absolute;
    top: 0;
}
</style>
